import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				PrimeFit Arena
			</title>
			<meta name={"description"} content={"From high-intensity interval training (HIIT) to calming yoga classes, each visit offers a new opportunity to challenge yourself and achieve your goals."} />
			<meta property={"og:title"} content={"PrimeFit Arena"} />
			<meta property={"og:description"} content={"From high-intensity interval training (HIIT) to calming yoga classes, each visit offers a new opportunity to challenge yourself and achieve your goals."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-5">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Frequently Asked Questions
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				Unleash your fitness potential - answers to help you on your journey
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						What fitness classes do you offer?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Our diverse offerings include high-intensity interval training (HIIT), yoga, strength training, and more, tailored to various fitness levels and preferences.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Do I need previous fitness experience to join?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Not at all! PrimeFit Arena welcomes individuals of all fitness levels, from beginners to seasoned athletes, offering programs designed to meet everyone's needs.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Can I try classes before committing to a membership?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Yes, we offer trial classes for those who want to experience our services firsthand before making a decision.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						What are the benefits of personal training?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Personal training offers customized workout plans, one-on-one coaching, and nutritional guidance, aimed at more effectively achieving your specific fitness goals.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						What facilities are available to club members?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Members have access to our state-of-the-art equipment, a variety of workout classes, spa and sauna facilities, and massage therapy services, ensuring a comprehensive wellness experience.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Are there any community events or competitions?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Absolutely! We regularly host member events, workshops, and fitness challenges to keep our community engaged and motivated.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc500072400020e9c714"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});